@import "constants.scss";

.startup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: calc(100svh - $APP_HEADER_HEIGHT);
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR;
}

.startup-text {
  width: 40%;
  color: darkgrey;
  text-align: center;
}

.startup-link {
  color: $SOLVER_BLUE;
}

.startup-login-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.startup-welcome {
  text-align: center;
  user-select: none;
}

.startup-welcome-text {
  text-align: center;
  user-select: none;
  font-family: $CODE_FONT_FAMILY;
}

.startup-welcome-header {
  font-size: 32px;
  margin-bottom: 8px;
  color: $SOLVER_PINK;
}

.startup-welcome-subheader {
  text-align: center;
  font-size: 14px;
  color: #dedede;
}

.startup-sign-up-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.startup-button-container {
  width: 100%;
  max-width: 300px;
}

.startup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 16px;
}

.startup-button .anticon {
  font-size: 20px;
}

.startup-button > button {
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startup-button-container.startup-fade-in {
  animation: startup-fadeIn 0.5s 0.6s forwards;
}

.startup-welcome,
.startup-sign-up-banner,
.startup-button-container {
  opacity: 0;
}

@keyframes startup-fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes startup-fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes startup-scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.startup-fade-in {
  animation: startup-fadeIn 0.5s forwards;
}

.startup-fade-out {
  animation: startup-fadeOut 0.5s forwards;
}
