@import "./constants.scss";

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .small-screen-hidden {
    display: none;
  }
}

@media (max-width: $SMALLER_SCREEN_WIDTH) {
  .smaller-screen-hidden {
    display: none;
  }
}
