@import "../constants.scss";

.message-group-user {
  display: grid;
  grid-template-columns: 30px auto 30px;
  grid-template-areas: ". message-group avatar";
}

.message-group-agent {
  display: grid;
  grid-template-columns: 30px auto 30px;
  grid-template-areas: "avatar message-group .";
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .message-group-user {
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
  }

  .message-group-agent {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.message-group-avatar {
  grid-area: avatar;
  display: flex;
  align-items: flex-start;
}

.message-group-avatar-user {
  justify-content: flex-end;
  // Offsetting slightly so the scrollbar doesn't clip the avatar.
  margin-right: 1px;
}

.message-group-avatar-agent {
  justify-content: flex-start;
  margin-left: 1px;
}

.message-group-avatar-image {
  border-radius: 50%;
  border: 1px solid grey;
  width: 24px;
  height: 24px;
  width: 24px;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .message-group-avatar {
    align-items: center;
  }

  .message-group-avatar:after {
    content: attr(data-alt);
    color: grey;
    margin-left: 5px;
    font-weight: 600;
  }

  .message-group-avatar-image {
    width: 20px;
    height: 20px;
  }

  .message-group-avatar-user {
    justify-content: flex-start;
    margin-right: 0;
  }

  .message-group-avatar-agent {
    margin-left: 0;
  }
}

.message-group-messages {
  grid-area: message-group;
}

.message-group-collapsible-messages {
  /* https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/ */
  display: grid;
  transition: grid-template-rows 0.2s ease-out;
}

.message-group-collapsible-messages-collapsed {
  grid-template-rows: 0fr;
}

.message-group-collapsible-messages-expanded {
  grid-template-rows: 1fr;
}

.message-group-collapsible-messages-inner {
  overflow: hidden;
}

.message-group-messages.user {
  justify-self: end;
  max-width: 75%;
}

.message-group-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -5px;
  margin-bottom: 5px;
}

.message-group-header-button {
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: grey;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .message-group-header-button {
    padding: 4px 0;
  }
}

.message-group-header::after {
  content: "";
  height: 1px;
  background-color: grey;
  flex-grow: 1;
}

.message-group-user.grayed-out,
.message-group-agent.grayed-out {
  opacity: 0.5;
}
