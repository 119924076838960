@import "../constants.scss";

.user-message-content {
  position: relative;
  width: 100%;

  .message-buttons {
    float: right;
    shape-outside: padding-box;
    display: flex;
    align-items: flex-start;
    gap: 4px;
  }
}

// Clear the float to prevent layout issues
.user-message-content::after {
  content: "";
  display: block;
  clear: both;
}

.grayed-out {
  opacity: 0.5;
}

.message-button {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  color: #e6e6e6 !important;
  min-width: 28px !important; // Ensure consistent width
  height: 28px !important; // Ensure consistent height
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important; // Center the icon
  gap: 4px !important; // Space between icon and text
  font-size: 0.9em !important;
  transition: all 0.15s ease;
  -webkit-appearance: none !important;
  appearance: none !important;

  .anticon {
    // Target Ant Design icons consistently
    font-size: 16px !important;
  }

  &:hover:not(:disabled):not([disabled]) {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: white !important;
  }

  &:disabled,
  &.ant-tooltip-disabled-compatible-wrapper button[disabled] {
    background-color: transparent !important;
    border-color: transparent !important;
    color: rgba(230, 230, 230, 0.5) !important;
    cursor: not-allowed;
  }
}

.edit-button {
  @extend .message-button;

  &:hover:not(:disabled) {
    background-color: rgba(24, 144, 255, 0.15);
  }

  &.cancel-mode:not(:disabled) {
    background-color: rgba(24, 144, 255, 0.25);
    color: #69c0ff !important; // Lighter, higher contrast blue
    border-color: rgba(24, 144, 255, 0.3);

    &:hover {
      background-color: rgba(24, 144, 255, 0.35) !important;
      color: #91d5ff !important; // Even lighter on hover
      border-color: rgba(24, 144, 255, 0.4);
    }
  }
}

.delete-button {
  @extend .message-button;
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-color: transparent;

  span:not(.anticon) {
    // Hide text span but not icon spans
    display: none;
  }

  &:hover:not(:disabled):not([disabled]):not(.ant-tooltip-disabled-compatible-wrapper) {
    background-color: rgba(220, 38, 38, 0.8) !important; // Red on hover
    border-color: rgba(220, 38, 38, 0.3);
    color: white !important;
  }

  &:disabled,
  &.ant-tooltip-disabled-compatible-wrapper {
    cursor: not-allowed;
    background-color: rgba(255, 255, 255, 0.08) !important;
    color: rgba(230, 230, 230, 0.5) !important;
    opacity: 1 !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.08) !important;
      color: rgba(230, 230, 230, 0.5) !important;
      border-color: transparent !important;
    }
  }
}

.message-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-loading-icon {
  font-size: 2em;
}

.turn-changes-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
}

.info-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: 16px;
  color: #999;
  font-size: 14px;
  background-color: transparent;
}

.agent-message:has(.info-message),
.info-message {
  background-color: transparent;
}

.info-cta-container {
  display: flex;
  gap: 12px;
}

.info-cta {
  background-color: transparent;
  border: 1px solid #6e6e6e;
  box-shadow: none;
  font-size: 14px;
  color: #999;
  padding: 4px 12px;
  border-radius: 6px;
}
